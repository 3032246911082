/* src/Contact.css */

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    margin-top: -50px;
    padding: 20px;
}

.contact-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 500px;
    text-align: center;
}

.contact-text {
    max-width: 400px; /* Adjust this value to control the width of the text */
    margin: 0 auto 20px auto; /* Center the text and add spacing */
}

.contact-card h2 {
    margin-top: 0;
    font-size: 24px;
}

.contact-card p {
    font-size: 18px;
    color: #333;
}

.email-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.contact-email {
    font-size: 18px;
    color: #007BFF;
    text-decoration: none;
}

.contact-email:hover {
    text-decoration: underline;
}

.copy-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #000000;
    margin-right: -2px;
}

.copy-button:hover {
    color: #0056b3;
}

.copy-button svg {
    vertical-align: middle;
}

.copy-icon {
    width: 16px; /* Adjust size as needed */
    height: 16px;
    vertical-align: middle;
}

/* Specific media query for mobile */
@media (max-width: 768px) {
    .contact-card {
        min-width: auto; /* Allow the card to adjust width on smaller screens */
        width: 90%; /* Take up most of the screen width but leave some padding */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .contact-text {
        max-width: 100%; /* Allow the text to take up the full width of the card */
    }

    .contact-card h2 {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .contact-card p {
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    .contact-email {
        font-size: 16px; /* Adjust font size for smaller screens */
    }
}