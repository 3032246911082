.products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.product-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 40px;
}

.image-container {
    position: relative;
    max-width: 100%;
    margin-bottom: 15px;
}

.product-image {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    display: block;
    margin: 0 auto;
}

.overlay-text {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 18px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease; /* Smooth transition */
}

.image-container:hover .arrow {
    opacity: 1; /* Show arrows on hover */
}

.left-arrow {
    left: 15px;
}

.right-arrow {
    right: 15px;
}

.thumbnail-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.thumbnail {
    width: 300px;
    height: auto;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
}

.thumbnail.active {
    border-color: #131313;
}

.product-title {
    font-size: 32px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 15px;
    color: #111827;
}

.product-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    text-align: left;
    align-items: flex-start;
    background: linear-gradient(135deg, #f5f7fa, #e4ebf5);
    padding-top: 15px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; 
}

.product-description, .product-features {
    flex: 1;
    padding: 20px;
    min-width: 300px;
}

.product-description, .product-features {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-description h3, .product-features h3 {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #111827;
    font-size: 24px;
}

.product-description p, .product-features ul {
    margin-top: 0px;
    line-height: 1.6;
}

.product-description {
    font-size: 18px;
    color: #374151;
}

.feature-heading {
    font-weight: bold;
}

.product-features {
    font-size: 18px;
    color: #4b5563;
}

.product-features ul {
    list-style-type: none;
    padding: 0;
}

.product-features li {
    margin-bottom: 20px;
    padding-left: 28px;
    position: relative;
    text-align: left;
}

.product-features li::before {
    content: '•';
    color: #6b7280;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 28px;
    line-height: 1;
}

.buy-now-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensure the container takes up the full width */
    margin-top: -60px;
    order: 3; /* Ensure it appears after the product-description and product-features */
}

.buy-now-button {
    background-color: #FFA500; /* Orange color */
    color: white;
    padding: 15px 50px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.buy-now-button:hover {
    background-color: #FF8C00; /* Darker orange on hover */
}

@media (max-width: 768px) {
    .products-container {
        width: 100%;
        padding: 0 10px;
    }

    .product-card {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    .products-container h2 {
        font-size: 20px;
    }

    .product-image {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
    }

    .thumbnail {
        width: 100px; /* Adjust thumbnail width */
        height: auto; /* Maintain aspect ratio */
    }

    .overlay-text {
        font-size: 12px;
        padding: 5px; /* Ensure padding does not overflow */
        border-radius: 8px; /* Adjust border-radius */
    }

    .product-content {
        flex-direction: column;
        gap: 10px;
        padding: 10px 0;
        box-sizing: border-box;
    }

    .product-description, .product-features {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 35px;
        box-sizing: border-box;
    }

    .product-description p, .product-features ul {
        margin-top: -10px;
    }

    .product-description p, .product-features li {
        word-break: break-word; /* Ensure words break properly */
    }

    .buy-now-container {
        margin-top: -40px;
    }
}





