.App-header {
    background-color: #282c34;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    position: relative;
    z-index: 1;
}

.logo-container {
    display: flex;
    align-items: center;
}

.App-logo {
    height: 50px;
    margin-right: 20px;
    margin-left: 18px;
}

.nav-links {
    display: none;
}

.nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%; /* Position menu just below the header */
    right: 0;
    background-color: #555555;
    width: 230px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.nav-links.open ul {
    flex-direction: column;
    padding: 10px;
    list-style: none;
}

.nav-links.open li {
    padding-bottom: 5px;
}

.nav-links.open a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    padding: 5px 0;
}

.menu-icon {
    height: 30px;
    cursor: pointer;
    margin-right: 25px;
}

.home-link {
    color: white;
    text-decoration: none;
}

.home-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .App-logo {
        position: relative;
        left: -5px;
    }
    .home-link {
        font-size: 26px;
        position: relative;
        top: -2px;
        left: -10px;
    }

    .nav-links.open {
        width: 150px;
    }
    .nav-links.open a {
        font-size: 16px;
        padding: 3px 5px;
    }
    .nav-links {
        display: none;
    }
    .menu-icon {
        display: block;
        position: relative;
        right: -10px;
    }
}
