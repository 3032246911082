body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background: linear-gradient(135deg, #f3f4f6, #e5e7eb); /* Ensure the same gradient */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(135deg, #f3f4f6, #e5e7eb); /* Ensure the same gradient */
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
}

footer a {
    color: #61dafb;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}
