/* src/Toast.css */

.toast {
    position: absolute;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0.9;
    transform: translate(-50%, 10px);
    animation: fadeInOut 3s;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 0.9; }
    90% { opacity: 0.9; }
    100% { opacity: 0; }
}
