/* src/styles/Footer.css */

.footer {
    background-color: #282c34;
    color: white;
    padding: 20px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: #61dafb;
    margin: 0 10px;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

/* Specific media query for mobile */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        margin-top: 10px;
    }

    .footer-links a {
        display: block;
        margin: 5px 0;
    }
}
