/* src/styles/Home.css */

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.home-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 0px;
}

.home-content {
    background: linear-gradient(135deg, #f5f7fa, #e4ebf5);
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-title {
    font-size: 36px;
    font-weight: bold;
    color: #111827;
    margin-bottom: 0px;
    text-align: center;
}

.home-welcome,
.home-mission {
    max-width: 800px;
    font-size: 18px;
    color: #374151;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.6;
}

.home-thank-you {
    max-width: 800px;
    font-size: 18px;
    color: #374151;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.6;
}

.home-product-description {
    max-width: 800px;
    font-size: 18px;
    color: #374151;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.6;
}

.home-mission-title {
    font-size: 28px;
    font-weight: bold;
    color: #111827;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
}

.home-flagship-title {
    font-size: 28px;
    font-weight: bold;
    color: #111827;
    margin-top: 20px;
    margin-bottom: -10px;
    text-align: center;
}

.home-product-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.home-product-link h3 {
    font-size: 24px;
    font-weight: bold;
    color: #111827;
    margin-bottom: 10px;
    text-align: center;
}

.home-product-image {
    max-width: 75%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Specific media query for mobile */
@media (max-width: 768px) {
    .home-container {
        width: 100%;
        padding: 0 10px;
    }

    .home-card {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    .home-content {
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        box-sizing: border-box;
    }

    .home-title {
        font-size: 28px;
    }

    .home-welcome,
    .home-mission,
    .home-product-description,
    .home-thank-you {
        font-size: 16px;
    }

    .home-mission-title,
    .home-flagship-title {
        font-size: 24px;
    }

    .home-product-link h3 {
        font-size: 20px;
    }

    .home-product-image {
        max-width: 100%;
    }

}
